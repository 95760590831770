
<template>
	<div>
		<v-card>
			<v-container>
				<v-row>
					<h2
						class="mt-6 ml-6"
					>
						Productividad Tunel
					</h2>
					<v-spacer></v-spacer>
					<v-btn
						class="mt-3 mr-3"
						small
						fab
						color="success"
						v-if="items.length > 0"
						@click="downloadExcel()"
					>
						<i 
							style="font-size:20px;" color="error" 
							class="fas fa-file-excel"
						></i>
					</v-btn>
					<v-btn
						class="mt-3 mr-3"
						small
						fab
						color="error"
						v-if="items.length > 0"
						@click="downloadPdf()"
					>
						<i 
							style="font-size:16px;" color="error" 
							class="fas fa-file-pdf"
						></i>
					</v-btn>
				</v-row>
				<v-row>
					<v-col>
						<s-date
							label="Fecha Inicio"
							v-model="filter.DateBegin"
						></s-date>
					</v-col>
					<v-col>
						<s-date
							label="Fecha Fin"
							v-model="filter.DateEnd"
						></s-date>
					</v-col>
					<v-col>
						<s-select
							label="Tunel"
							:items="tunnels"
							item-text="ChaDescription"
							item-value="ChaID"
							v-model="ChaID"
						></s-select>
					</v-col>
					<v-col>
						<v-btn
							color="info" 
							fab	
							@click="keyupEnter()"
						>
							<i
								class="fas fa-search"
							></i>
						</v-btn>
					</v-col>
				</v-row>

				<v-container v-for="item in items" :key="item.RcfNumberReferralGuide"> 
					<v-row>
						<v-col>
							{{item.Tunnel}}
							<v-divider></v-divider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" class="pt-0">
							<v-list>
								<v-list-item>
									<v-list-item-avatar>
										<v-btn
											color="info" 
											fab					
										>
											<i 
												class="fas fa-clock" 
												style="font-size:18px"
											>
											</i>
										</v-btn>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											Fecha Inicio
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.Fecha_Inicio}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-avatar>
										<v-btn
											color="info" 
											fab					
										>
											<i 
												class="fas fa-clock" 
												style="font-size:18px"
											>
											</i>
										</v-btn>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											Fecha Fin
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.Fecha_Fin == null ? '-' : item.Fecha_Fin}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-avatar>
										<v-btn
											color="warning" 
											fab					
										>
											<i 
												class="fas fa-user-clock" 
												style="font-size:18px"
											>
											</i>
										</v-btn>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											Tunel
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.Tunnel}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								
							</v-list>
						</v-col>

						<v-col cols="6" class="pt-0">
							<v-list-item>
								<v-list-item-avatar>
									<v-btn
										color="info" 
										fab					
									>
										<i 
											class="fas fa-truck"
											style="font-size:18px"
										>
										</i>
									</v-btn>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										Cantidad Pallets
									</v-list-item-title>
									<v-list-item-subtitle>
										{{item.Cantidad_Pallets}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-avatar>
									<v-btn
										color="success" 
										fab					
									>
										<i 
											class="fas fa-door-open"
											style="font-size:18px"
										>
										</i>
									</v-btn>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										Pallets
									</v-list-item-title>
									<v-list-item-subtitle>
										{{item.Pallets}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

						</v-col>
					</v-row>
				</v-container>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import _sCoolingChamberService from '@/services/FreshProduction/PrfCoolingChamberService';
	import _sPrfChamberService from "../../../services/FreshProduction/PrfChamberService";

	export default {
		data() {
			return {
				ChaID: 0,
				filter:{},
				items: [],
				tunnels: [],
			}
		},
		
		methods: {
			Initialize()
			{
				_sPrfChamberService.list(this.$fun.getUserID())
				.then((resp) => {
					if(resp.status == 200)
					{
						this.tunnels = resp.data.filter(e => {
							return e.ChaDescription.match("Tu.*")
						})
					}
				});
			},

			keyupEnter() {
				this.filter.ChaID = this.ChaID;
				console.log('filter ', this.filter);
				_sCoolingChamberService
				.prfcoolingchamberbatchreport(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.items = resp.data;
						console.log('items ', this.items);
					}
				})
			},

			downloadExcel(){
				this.$fun.alert("¿Seguro de descargar?", "question")
				.then(r => {
					if(r.value){
						_sCoolingChamberService
						.convertexcel(this.items, this.$fun.getUserID())
						.then(resp => {
							this.$fun.downloadFile(resp.data, this.$const.TypeFile.EXCEL, this.$fun.getUserID());
						})
					}
				})
			},

			downloadPdf(){
				this.$fun.alert("¿Seguro de descargar PDF?", "question")
				.then(r => {
					if(r.value){
						_sCoolingChamberService
						.converterpdf(this.filter, this.$fun.getUserID())
						.then(resp => {
							this.$fun.downloadFile(resp.data, this.$const.TypeFile.PDF, this.$fun.getUserID());
						})
					}
				})
			}
		},

		created () {
			this.Initialize();
		},
	}
</script>